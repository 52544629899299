export const paths = {
  home: '/',
  about_us: '/about-us',
  announcements: '/announcements',
  affiliate_earnings: '/user-referrals',
  auth: {
    root: '/auth',
    linkedin: '/auth/linkedin',
    login: '/auth/login',
  },
  disclaimer: '/disclaimer',
  events: '/events',
  faq: '/faq',
  incubators: {
    root: '/incubators',
    search: '/incubators/search',
  },
  institutions: {
    root: '/institutions',
    search: '/institutions/search',
  },
  investors: {
    root: '/investors',
    search: '/investors/search',
  },
  my_events: '/my-events',
  my_ratings: '/my-ratings',
  page_management: {
    root: '/page-management',
    overview: '/page-management/overview',
    incubator: {
      builder: '/page-management/incubator/builder',
      cohorts: '/page-management/incubator/cohorts',
      events: '/page-management/incubator/events',
      overview: '/page-management/incubator/overview',
      payments: '/page-management/incubator/payments',
      programs: '/page-management/incubator/programs',
      services: '/page-management/incubator/services',
      products: '/page-management/incubator/products',
      submissions: '/page-management/incubator/submissions',
      announcements: '/page-management/incubator/announcements',
    },
    investor: {
      builder: '/page-management/investor/builder',
      events: '/page-management/investor/events',
      overview: '/page-management/investor/overview',
      payments: '/page-management/investor/payments',
      programs: '/page-management/investor/programs',
      services: '/page-management/investor/services',
      products: '/page-management/investor/products',
      ratings: '/page-management/investor/ratings',
      announcements: '/page-management/investor/announcements',
      submissions: {
        root: '/page-management/investor/submissions',
        settings: '/page-management/investor/submissions/settings',
      },
    },
    sme: {
      builder: '/page-management/sme/builder',
      events: '/page-management/sme/events',
      overview: '/page-management/sme/overview',
      payments: '/page-management/sme/payments',
      programs: '/page-management/sme/programs',
      services: '/page-management/sme/services',
      products: '/page-management/sme/products',
      ratings: '/page-management/sme/ratings',
      submissions: '/page-management/sme/submissions',
      announcements: '/page-management/sme/announcements',
      scheduledsmemeetings: '/page-management/sme/scheduled-meetings',
    },
    institution: {
      builder: '/page-management/institution/builder',
      events: '/page-management/institution/events',
      overview: '/page-management/institution/overview',
      payments: '/page-management/institution/payments',
      programs: '/page-management/institution/programs',
      services: '/page-management/institution/services',
      products: '/page-management/institution/products',
      submissions: '/page-management/institution/submissions',
      announcements: '/page-management/institution/announcements',
      scheduledinstitutionmeetings: '/page-management/institution/scheduled-meetings',
    },
    startup: {
      blog: '/page-management/startup/blog',
      builder: '/page-management/startup/builder',
      events: '/page-management/startup/events',
      products: '/page-management/startup/products',
      overview: '/page-management/startup/overview',
      plans: '/page-management/startup/plans',
      stripe_settings: '/page-management/startup/stripe-settings',
      submissions: {
        root: '/page-management/startup/submissions',
        announcements: '/page-management/startup/announcement-submissions',
      },
      payments: '/page-management/startup/payments',
    },
  },
  password_reset: '/password-reset',
  products: { root: '/products', detail: (id: number) => `/products/${id}` },
  profile: {
    root: '/profile',
    notifications: '/profile/notifications',
    settings: {
      root: '/profile/settings',
      credentials: '/profile/settings/credentials',
      notifications: '/profile/settings/notifications',
      delete: '/profile/settings/delete',
    },
  },
  programs: '/programs',
  purchased_contents: '/purchased-contents',
  redirect: '/redirect',
  services: '/services',
  smes: {
    root: '/smes',
    search: '/smes/search',
  },
  startups: {
    root: '/startups',
    blog: '/startups/blog',
    search: '/startups/search',
  },
  users: '/users',
};
