// redux
import { UserGroupState } from '@/redux/slices/user-group';
import { useSelector } from '@/redux/store';

const useSelectedUserGroup = () => {
  const state = useSelector((state) => state.userGroup) as UserGroupState;

  if (!state) throw new Error('User Group is not loaded!');

  return state;
};

export default useSelectedUserGroup;
